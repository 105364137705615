import { Button, Text } from '@workos-inc/component-library';
import { Card } from 'components/card';
import React, { FC } from 'react';

interface ConfirmProps {
  buttonText?: string;
  disabled?: boolean;
  isLoading?: boolean;
  label: string;
  onClick?: () => void;
}

export const Confirm: FC<Readonly<ConfirmProps>> = ({
  buttonText = 'Continue to Next Step',
  disabled,
  isLoading,
  label,
  onClick,
}) => (
  <Card>
    <div className="flex justify-between items-center">
      <Text as="p">{label}</Text>

      <Button disabled={disabled} isLoading={isLoading} onClick={onClick}>
        {buttonText}
      </Button>
    </div>
  </Card>
);
