import { Text } from '@workos-inc/component-library';
import { Confirm } from 'components/confirm';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { DirectoryStepProps } from 'interfaces/step-props';
import React from 'react';
import { MapAttributesStep } from './map-attributes-step';
import { ArrayStepsConfig } from './steps-config';

export const stepsOkta: ArrayStepsConfig = {
  providerLabel: 'Okta SCIM v2',
  type: 'Array',
  steps: [
    {
      name: 'Create Okta Application',
      // eslint-disable-next-line react/display-name
      render: ({ appName, onNextStep }: DirectoryStepProps) => (
        <>
          <Text>
            From your Okta Application dashboard, select "Browse App Catalog".
          </Text>

          <Img
            priority
            height={590}
            src="/admin-portal/directory-sync/okta/v1/okta-1.png"
            width={2528}
          />

          <Text>
            Next, search for "SCIM 2.0 Test App (Oauth Bearer Token)" and select
            the corresponding result.
          </Text>

          <Img
            priority
            height={1036}
            src="/admin-portal/directory-sync/okta/v1/okta-2.png"
            width={2060}
          />

          <Text>On the following page, click "Add".</Text>

          <Img
            height={716}
            src="/admin-portal/directory-sync/okta/v1/okta-3.png"
            width={2054}
          />

          <Text>
            Give your application a descriptive name such as{' '}
            <strong>{appName}</strong> and click Next.
          </Text>

          <Img
            height={1114}
            src="/admin-portal/directory-sync/okta/v1/okta-4.png"
            width={1492}
          />

          <Text>
            Many applications will work with the default configuration that is
            set on your new application. If you require any additional
            configuration for your directory such as configuring Attribute
            Statements, do so on the Sign-On Options page. Click "Done" to
            complete creating your application.
          </Text>

          <Img
            height={1632}
            src="/admin-portal/directory-sync/okta/v1/okta-5.png"
            width={1116}
          />

          <Confirm
            label="I’ve created my Okta application."
            onClick={onNextStep}
          />
        </>
      ),
    },
    {
      name: 'Configure Okta API Integration',
      // eslint-disable-next-line react/display-name
      render: ({ directory, onNextStep }: DirectoryStepProps) => (
        <>
          <Text>
            Inside your Enterprise Okta Admin Panel, click on the "Provisioning"
            tab. Then, click on "Configure API Integration".
          </Text>

          <Img
            priority
            height={986}
            src="/admin-portal/directory-sync/okta/v1/okta-6.png"
            width={2032}
          />

          <Text>Click "Enable API Integration".</Text>

          <Img
            priority
            height={1204}
            src="/admin-portal/directory-sync/okta/v1/okta-7.png"
            width={2036}
          />

          <Text>
            Copy and paste the endpoint into the SCIM 2.0 Base Url field.
          </Text>

          <CopyInput label="Copy this Endpoint" value={directory?.endpoint} />

          <Text>
            Copy and paste the Bearer Token into the OAuth Bearer Token field.
          </Text>

          <CopyInput
            label="Copy this Bearer Token"
            type="password"
            value={directory?.bearer_token}
          />

          <Text>Click "Test API Credentials" and then "Save".</Text>

          <Confirm
            label="I’ve tested my API credentials."
            onClick={onNextStep}
          />
        </>
      ),
    },
    {
      name: 'Set up Attribute Mapping',
      isCustomAttributeMapping: true,
      // eslint-disable-next-line react/display-name
      render: (directoryStepProps: DirectoryStepProps) => (
        <MapAttributesStep {...directoryStepProps}>
          <Text>
            There are additional attributes needed by the application for it to
            fully function. Note that basic attributes like a user's name or
            email are already mapped, and so are not present below.
          </Text>

          <Text>
            To see the names of attributes that you can map from your Directory
            Provider, return to the "Provisioning" tab in your Enterprise Okta
            Admin Panel and click on "To App" in the sidebar.
          </Text>

          <Img
            priority
            height={1230}
            src="/admin-portal/directory-sync/okta/v1/okta-map-attributes-1.png"
            width={2050}
          />

          <Text>
            Scroll down to the section labeled "[Your Application's Name]
            Attribute Mappings" where you should see a table containing
            attributes available to the application. Any of the camel-cased
            values can be used as a value for an application attribute.
          </Text>

          <Text>
            For example, if you wanted to use the value of "Username" for an
            attribute mapping, you would enter "userName" as the "Directory
            Provider Value".
          </Text>

          <Img
            priority
            height={1360}
            src="/admin-portal/directory-sync/okta/v1/okta-map-attributes-2.png"
            width={1662}
          />

          <Text>
            Map attributes from your Directory Provider's responses to the
            attributes shown below.
          </Text>
        </MapAttributesStep>
      ),
    },
    {
      name: 'Configure Provisioning Actions',
      // eslint-disable-next-line react/display-name
      render: ({ onNextStep }: DirectoryStepProps) => (
        <>
          <Text>
            In the "To App" navigation section, click "Edit" and then check to
            enable the following actions:
          </Text>

          <ul className="my-4 space-y-3 list-disc list-inside text-gray-lightmode-300">
            <li>Create Users</li>
            <li>Update User Attributes</li>
            <li>Deactivate Users</li>
          </ul>

          <Img
            priority
            height={1442}
            src="/admin-portal/directory-sync/okta/v1/okta-8.png"
            width={1738}
          />

          <Confirm
            label="I’ve enabled the actions listed above."
            onClick={onNextStep}
          />
        </>
      ),
    },
    {
      name: 'Assign People & Groups to Okta Application',
      // eslint-disable-next-line react/display-name
      render: ({ onNextStep }: DirectoryStepProps) => (
        <>
          <Text>
            On the "Assignments" tab of your Okta Application click the "Assign"
            button and select "Assign to People".
          </Text>

          <Img
            priority
            height={1088}
            src="/admin-portal/directory-sync/okta/v1/okta-12.png"
            width={1472}
          />

          <Text>
            Find the users that you wish to assign and click the "Assign" button
            next to them.
          </Text>

          <Img
            priority
            height={1178}
            src="/admin-portal/directory-sync/okta/v1/okta-13.png"
            width={1362}
          />

          <Text>
            To complete assigning the users, click "Save and Go Back".
          </Text>

          <Img
            height={806}
            src="/admin-portal/directory-sync/okta/v1/okta-14.png"
            width={1359}
          />

          <Confirm
            label="I’ve completed my assignments."
            onClick={onNextStep}
          />
        </>
      ),
    },
    {
      name: 'Push Groups',
      // eslint-disable-next-line react/display-name
      render: ({ appName, onNextStep }: DirectoryStepProps) => (
        <>
          <Text>
            On the "Push Groups" tab of your Okta Application click the "Push
            Groups" button and select "Find groups by name".
          </Text>

          <Img
            priority
            height={1084}
            src="/admin-portal/directory-sync/okta/v1/okta-15.png"
            width={2036}
          />

          <Text>
            Search for the group that you wish to push, select it and click
            "Save" to push your group to {appName}.
          </Text>

          <Img
            priority
            height={1624}
            src="/admin-portal/directory-sync/okta/v1/okta-16.png"
            width={2019}
          />

          <Confirm label="I’ve pushed my groups." onClick={onNextStep} />
        </>
      ),
    },
  ],
};
