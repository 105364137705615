import { InputGroup, Text } from '@workos-inc/component-library';
import { Card } from 'components/card';
import { Confirm } from 'components/confirm';
import { Form } from 'components/form';
import { CopyInput } from 'components/input';
import { ConnectionStepProps } from 'interfaces/step-props';
import React, { ChangeEvent, FC, useState } from 'react';
import { graphql } from 'utils/graphql';

export const OpenIDProvideInformation: FC<Readonly<ConnectionStepProps>> = ({
  onNextStep,
}) => {
  const [identityProvider, setIdentityProvider] = useState('');

  const handleIdPSubmit = (): void => {
    void graphql().RequestIdentityProvider({
      identityProvider,
    });
    onNextStep();
  };

  return (
    <>
      <Card>
        <Form isInline disabled={!identityProvider} onSubmit={handleIdPSubmit}>
          <InputGroup
            autoFocus
            id="identity_provider"
            label="Which Identity Provider are you connecting to?"
            name="identity_provider"
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setIdentityProvider(event.target.value)
            }
            placeholder="e.g. Okta, Azure AD, OneLogin..."
            value={identityProvider}
          />
        </Form>
      </Card>
    </>
  );
};

export const OpenIDCreateApplication: FC<Readonly<ConnectionStepProps>> = ({
  connection,
  onNextStep,
}) => (
  <>
    <Text>
      For SSO to properly function with your Identity Provider, you'll need to
      create and configure your application to:
    </Text>

    <ul className="my-4 space-y-3 list-disc list-inside text-gray-lightmode-300">
      <li>Support the authorization code grant type</li>
      <li>Have the URL below listed as a login redirect URI</li>
    </ul>

    <CopyInput
      label="Copy your login redirect URI"
      value={connection?.oidc_redirect_uri}
    />

    <Confirm
      label="I've created and configured my application"
      onClick={onNextStep}
    />
  </>
);

export const OpenIDAddClaims: FC<Readonly<ConnectionStepProps>> = ({
  onNextStep,
}) => (
  <>
    <Text>
      Add the "sub", "email", "given_name", and "family_name" claims to the user
      ID token in your OIDC provider settings. If the "given_name" claim is not
      available, the "name" claim will be used instead. For many providers,
      these claims are included by default, but for other providers you will
      need to add these claims.
    </Text>

    <Confirm
      label="I've added claims to the user ID token."
      onClick={onNextStep}
    />
  </>
);

export const OpenIDProvideClientCredentials: FC<
  Readonly<ConnectionStepProps>
> = ({ appName, connection, errors, isLoading, onInputChange, onNextStep }) => (
  <>
    <Text>
      After creating an application, a Client ID and Client Secret would've been
      provisioned for you by your Identity Provider. These credentials are used
      to authenticate users from {appName}.
    </Text>

    <Card>
      <Form
        disabled={
          !connection?.oidc_client_id || !connection?.oidc_client_secret
        }
        isLoading={isLoading}
        isUpdate={!!(errors?.oidc_client_id || errors?.oidc_client_secret)}
        onSubmit={onNextStep}
      >
        <InputGroup
          autoFocus
          error={errors?.oidc_client_id?.message}
          id="oidc_client_id"
          label="Client ID"
          name="oidc_client_id"
          onChange={onInputChange}
          value={connection?.oidc_client_id ?? undefined}
        />

        <InputGroup
          error={errors?.oidc_client_secret?.message}
          id="oidc_client_secret"
          label="Client Secret"
          name="oidc_client_secret"
          onChange={onInputChange}
          value={connection?.oidc_client_secret ?? undefined}
        />
      </Form>
    </Card>
  </>
);

export const OpenIDProvideDiscoveryEndpoint: FC<
  Readonly<ConnectionStepProps>
> = ({
  connection,
  errors,
  isLoading,
  onInputChange,
  onNextStep,
  validationErrors,
}) => (
  <>
    <Text>
      Your Identity Provider's Discovery Endpoint contains important
      configuration information.
    </Text>

    <Card>
      <Form
        isInline
        disabled={!connection?.oidc_discovery_endpoint}
        isLoading={isLoading}
        isUpdate={!!errors?.oidc_discovery_endpoint}
        onSubmit={onNextStep}
      >
        <InputGroup
          autoFocus
          error={
            errors?.oidc_discovery_endpoint?.message ||
            validationErrors?.oidc_discovery_endpoint?.message
          }
          id="oidc_discovery_endpoint"
          label="Discovery Endpoint"
          name="oidc_discovery_endpoint"
          onChange={onInputChange}
          placeholder="https://your-domain.com/.well-known/openid-configuration"
          value={connection?.oidc_discovery_endpoint ?? undefined}
        />
      </Form>
    </Card>
  </>
);
